import React from 'react';
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group';

export default class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    const timeout = 500
    const getTransitionStyles = {
      entering: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
      },
      entered: {
        position: '',
        top: '',
        left: '',
        width: '',
        opacity: '',
      },
      exiting: {
        display: 'block',
        position: 'relative',
        zIndex: 999,
        backgroundColor: 'white',
      },
    }

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <div style={{ ...getTransitionStyles[status] }} className={status}>
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}
