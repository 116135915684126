// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-address-accepted-js": () => import("./../../../src/pages/address-accepted.js" /* webpackChunkName: "component---src-pages-address-accepted-js" */),
  "component---src-pages-email-confirmation-js": () => import("./../../../src/pages/email-confirmation.js" /* webpackChunkName: "component---src-pages-email-confirmation-js" */),
  "component---src-pages-email-not-confirmed-js": () => import("./../../../src/pages/email-not-confirmed.js" /* webpackChunkName: "component---src-pages-email-not-confirmed-js" */),
  "component---src-pages-final-js": () => import("./../../../src/pages/final.js" /* webpackChunkName: "component---src-pages-final-js" */),
  "component---src-pages-forgotten-password-affirmation-js": () => import("./../../../src/pages/forgotten-password-affirmation.js" /* webpackChunkName: "component---src-pages-forgotten-password-affirmation-js" */),
  "component---src-pages-forgotten-password-js": () => import("./../../../src/pages/forgotten-password.js" /* webpackChunkName: "component---src-pages-forgotten-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-log-out-js": () => import("./../../../src/pages/log-out.js" /* webpackChunkName: "component---src-pages-log-out-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-affirmation-js": () => import("./../../../src/pages/register-affirmation.js" /* webpackChunkName: "component---src-pages-register-affirmation-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-affirmation-js": () => import("./../../../src/pages/reset-password-affirmation.js" /* webpackChunkName: "component---src-pages-reset-password-affirmation-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-share-address-js": () => import("./../../../src/pages/share-address.js" /* webpackChunkName: "component---src-pages-share-address-js" */),
  "component---src-templates-answer-js": () => import("./../../../src/templates/answer.js" /* webpackChunkName: "component---src-templates-answer-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */)
}

